<template>
  <form>
    <b-field position="is-centered">
      <b-radio-button
        disabled
        size="is-medium"
        v-for="registryOption in registryOptions"
        :value="companyHistory.registry"
        :native-value="registryOption.value"
        :key="registryOption.id"
      >
        <span>{{ registryOption.name }}</span>
      </b-radio-button>
    </b-field>

    <b-field v-show="companyHistory.registry === 'CARTORIO'" label="Cartório">
      <b-input disabled placeholder="Cartório" :value="companyHistory.registryOffice" />
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field expanded label="UF">
        <b-select disabled expanded placeholder="UF" :value="companyHistory.registryState">
          <option v-for="option in ufOptions" :key="option.id" :value="option.value">
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
      <b-field label="Nr. Registro" expanded>
        <b-input disabled :value="companyHistory.registryNumber" placeholder="Nr. Registro" />
      </b-field>
      <b-field label="Data Registro" expanded>
        <b-datepicker
          disabled
          editable
          placeholder="Data Registro"
          icon="calendar-today"
          v-mascara:data
          :value="companyHistory.registryDate"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        ></b-datepicker>
      </b-field>
    </b-field>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { ufOptions } from '@/utils/data';

export default {
  name: 'ViewCompanyRegistry',
  data() {
    const registryOptions = [
      { id: 1, name: 'Junta Comercial', value: 'JUNTA' },
      { id: 2, name: 'Cartório', value: 'CARTORIO' },
    ];
    return {
      ufOptions,
      registryOptions,
    };
  },
  computed: {
    ...mapState('companies', ['companyHistory']),
  },
};
</script>
